import {AnyAction} from 'redux'
import {TICKETS_PICKER_COLLAPSE_DESCRIPTION, TICKETS_PICKER_EXPAND_DESCRIPTION} from '../actions/tickets-picker'
import {TicketsPicker} from '../types/state'

const defaultState: TicketsPicker = {
  tickets: {},
}

export const ticketsPicker = (state: TicketsPicker = defaultState, action: AnyAction) => {
  switch (action.type) {
    case TICKETS_PICKER_EXPAND_DESCRIPTION:
      return updateTicketFlag(state, action.payload, 'expandedDescription', true)
    case TICKETS_PICKER_COLLAPSE_DESCRIPTION:
      return updateTicketFlag(state, action.payload, 'expandedDescription', false)
    default:
      return state
  }
}

const updateTicketFlag = (state: TicketsPicker, ticketDefId: string, flag: string, value: boolean) => ({
  ...state,
  tickets: {
    ...state.tickets,
    [ticketDefId]: {...(state.tickets[ticketDefId] || {}), [flag]: value},
  },
})
