import {getPreliminaryInvoice, getTaxConfig} from '@wix/wix-events-commons-statics'
import {IWixAPI} from '@wix/yoshi-flow-editor'
import {transformEvent} from '../../../../commons/services/seo'
import {getMultilingualLanguage, getPageUrl, isMainLanguage} from '../../../../commons/utils/wix-code-api'
import {getEvent} from '../selectors/event'
import {getTickets} from '../selectors/tickets'
import {State} from '../types/state'

interface RenderSEOTagsParams {
  wixCodeApi: IWixAPI
  state: State
}

export const renderSEOTags = async ({wixCodeApi, state}: RenderSEOTagsParams) => {
  const pageUrl = await getPageUrl(wixCodeApi)
  const language = isMainLanguage(wixCodeApi) ? undefined : getMultilingualLanguage(wixCodeApi)
  const itemData = getItemData(state, pageUrl, language)
  const seoData = getEvent(state).seoSettings?.advancedSeoData ?? {}

  if (itemData) {
    wixCodeApi.seo.renderSEOTags({
      itemType: 'EVENTS_PAGE',
      itemData,
      seoData,
    })
  }
}

export const setRobotsNoIndex = (wixCodeApi: IWixAPI) => {
  const METATAG_NAME_ROBOTS = 'robots'
  const METATAG_CONTENT_NOINDEX = 'noindex'

  const metaTags = wixCodeApi.seo.metaTags || []
  const robotsIndex = metaTags.findIndex(tag => tag?.name === METATAG_NAME_ROBOTS)
  if (robotsIndex > -1) {
    metaTags[robotsIndex].content = METATAG_CONTENT_NOINDEX
  } else {
    // @ts-expect-error
    metaTags.push({name: METATAG_NAME_ROBOTS, content: METATAG_CONTENT_NOINDEX})
  }
  wixCodeApi.seo.setMetaTags?.(metaTags)
}

const getItemData = (state: State, pageUrl: string, language: string | undefined) => {
  try {
    const event = getEvent(state)

    return {
      event: transformEvent(event, pageUrl, language, state.dates.events),
      tickets: getTickets(state)?.length ? transformTickets(getTickets(state), event) : getTickets(state),
    }
  } catch (e) {
    console.warn(e)
    return null
  }
}

export const transformTickets = (tickets: wix.events.ticketing.TicketDefinition[], event: wix.events.Event) => {
  return tickets.map(ticket => ({
    ...ticket,
    finalPrice: {
      ...getPreliminaryInvoice(tickets, {[ticket.id]: {quantity: 1}}, getTaxConfig(event) as wix.events.TaxConfig)
        .grandTotal,
    },
  }))
}
