import {isDonationLessThanMinimum, isDonationTicketDefinition} from '@wix/wix-events-commons-statics'
import {DONATION_ERROR} from '../constants/donation-error'

interface CheckDonationErrorOptions {
  excludeErrors: DONATION_ERROR[]
}

export const isDonationValid = (
  ticket: wix.events.ticketing.TicketDefinition,
  donation: string,
  options?: CheckDonationErrorOptions,
) => {
  const error = checkDonationError(ticket, donation, options)
  return !error
}

export const isEmptyDonation = (donation: string) => donation === undefined || donation === ''

export const checkDonationError = (
  ticket: wix.events.ticketing.TicketDefinition,
  donation: string,
  options?: CheckDonationErrorOptions,
): DONATION_ERROR => {
  const {excludeErrors} = options || {}

  if (!isDonationTicketDefinition(ticket)) {
    return
  }

  let error: DONATION_ERROR

  if (isEmptyDonation(donation)) {
    error = DONATION_ERROR.EMPTY_DONATION
  } else if (isDonationLessThanMinimum(ticket, Number(donation))) {
    error = DONATION_ERROR.MINIMUM_NOT_REACHED
  }

  return error && (!excludeErrors || !excludeErrors.includes(error)) ? error : undefined
}
