import {getBuyerTotal} from '@wix/wix-events-commons-statics'
import {FormStep} from '../constants/constants'
import {RegFormData} from '../types/registration'
import {State} from '../types/state'
import {isOrderCompleted} from './placed-order'

export const getCurrentStep = (state: State) => state.checkout.currentStep

export const isValidPaymentAdded = (state: State) => state.checkout.validPaymentAdded

export const getBuyerDetails = (state: State) => state.checkout.buyerDetails

export const getTicketsDetails = (state: State) => state.checkout.ticketsDetails

export const getTicketDetails = (state: State, index: number) => state.checkout.ticketsDetails[index]

export const getUseBuyerDetails = (state: State) => state.checkout.useBuyerDetails

export const isBuyerDetailsStep = (state: State) => getCurrentStep(state) === FormStep.BuyerDetails

export const getExpandedTicketIndex = (state: State) => state.checkout.expandedTicketIndex

export const isStepVisible = ({
  step,
  invoice,
  giftCardPaymentDetails,
  assignedTicketsEnabled,
  hasPolicies,
  isTemplate,
  eventPreview,
}: IsStepVisibleArgs) => {
  switch (step) {
    case FormStep.TicketsDetails:
      return !isTemplate && !eventPreview && assignedTicketsEnabled
    case FormStep.Policies:
      return !isTemplate && !eventPreview && hasPolicies
    case FormStep.Payment: {
      const buyerTotal = getBuyerTotal(invoice.grandTotal, giftCardPaymentDetails)

      return !isTemplate && !eventPreview && Number(buyerTotal.amount) > 0
    }
    default:
      return true
  }
}

export const isAllTicketDetailsValid = (state: State) => {
  const {ticketsDetailsValidity} = state.checkout
  return ticketsDetailsValidity.every(Boolean)
}

export const getInvalidTicketIndex = (state: State) => {
  if (isAllTicketDetailsValid(state)) {
    return -1
  }

  const {ticketsDetailsValidity} = state.checkout
  const invalidTicketIndex = ticketsDetailsValidity.findIndex(el => !!el === false)
  return invalidTicketIndex
}

export const isStepCompleted = ({
  step,
  placedOrder,
  assignedTicketsEnabled,
  buyerDetails,
  validPaymentAdded,
  agreedWithPolicies,
  hasPolicies,
  allTicketDetailsValid,
}: IsStepCompletedArgs) => {
  switch (step) {
    case FormStep.BuyerDetails:
      return hasPolicies || assignedTicketsEnabled ? Boolean(buyerDetails) : isOrderCompleted(placedOrder)
    case FormStep.TicketsDetails:
      return hasPolicies ? allTicketDetailsValid : isOrderCompleted(placedOrder)
    case FormStep.Policies:
      return agreedWithPolicies && isOrderCompleted(placedOrder)
    case FormStep.Payment:
      return validPaymentAdded
    default:
      return false
  }
}

interface IsStepVisibleArgs {
  step: FormStep
  invoice: wix.events.ticketing.Invoice
  giftCardPaymentDetails: wix.events.ticketing.GiftCardPaymentDetails[] | undefined
  assignedTicketsEnabled: boolean
  hasPolicies: boolean
  isTemplate: boolean
  eventPreview: boolean
}

interface IsStepCompletedArgs {
  step: FormStep
  placedOrder: wix.events.ticketing.Order
  assignedTicketsEnabled: boolean
  buyerDetails: RegFormData
  validPaymentAdded: boolean
  agreedWithPolicies: boolean
  hasPolicies: boolean
  allTicketDetailsValid: boolean
}
