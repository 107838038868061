import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {PlaceWithTicketInfo, hasPricingOptions} from '@wix/wix-events-commons-statics'
import {resetAreaCounterValue, setAreaCounterValue} from '../../actions/seating/place'
import {describeSeatingPlan} from '../../actions/seating/plan'
import {isPlaceArea} from '../../selectors/seating/place'
import {PlacesState} from '../../types/state'

const initialState: PlacesState = {
  places: [],
  areaCounterValues: {},
}

const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    updatePlace(
      state,
      {
        payload: {place: partialPlace, clearOthers},
      }: PayloadAction<{place: Partial<PlaceWithTicketInfo> & {id: string}; clearOthers?: boolean; origin?: string}>,
    ) {
      state.places = state.places.map(place => {
        if (place.id === partialPlace.id) {
          return updatePlaceDiff(place, partialPlace)
        } else {
          return clearOthers ? updatePlaceDiff(place, {quantity: 0}) : place
        }
      })
    },
  },
  extraReducers: builder => {
    builder
      .addCase(describeSeatingPlan.fulfilled, (state, action) => {
        state.places = action.payload.places
      })
      .addCase(setAreaCounterValue, (state, {payload: {placeId, count}}) => {
        state.areaCounterValues[placeId] = count
      })
      .addCase(resetAreaCounterValue, (state, {payload: {place, keepQuantityIfNeeded, strictId}}) => {
        const {id, quantity} = place
        const placeId = strictId ?? id

        if (isPlaceArea(place)) {
          if (quantity && keepQuantityIfNeeded) {
            state.areaCounterValues[placeId] = quantity
          } else if (!quantity) {
            state.areaCounterValues[placeId] = 1
          }
        }
      })
  },
})

const updatePlaceDiff = (oldPlace: PlaceWithTicketInfo, diff: Partial<PlaceWithTicketInfo>) => {
  const updatedPlace: PlaceWithTicketInfo = {
    ...oldPlace,
    ...diff,
  }

  if (oldPlace.quantity !== updatedPlace.quantity) {
    if (
      hasPricingOptions(updatedPlace.ticket) &&
      updatedPlace.selectedPricingOptionIds?.length !== updatedPlace.quantity
    ) {
      updatedPlace.selectedPricingOptionIds = new Array(updatedPlace.quantity).fill(
        updatedPlace.pricingOptionId ?? updatedPlace.selectedPricingOptionIds?.[0],
      )
    }
  } else if (oldPlace.selectedPricingOptionIds?.length !== updatedPlace.selectedPricingOptionIds?.length) {
    updatedPlace.quantity = updatedPlace.selectedPricingOptionIds?.length
  }

  if (oldPlace.quantity > 0 && updatedPlace.quantity === 0) {
    updatedPlace.donation = undefined
    updatedPlace.selectedPricingOptionIds = undefined
  }

  if (oldPlace.quantity === 0 && updatedPlace.quantity > 0) {
    updatedPlace.timeAddedToBasket = new Date().getTime()
  }

  return updatedPlace
}

export const {updatePlace} = placesSlice.actions

export default placesSlice.reducer
