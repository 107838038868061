import {VisitorType} from '@wix/events-types'
import {hookToAttributeSelector} from '@wix/panda-js-utils'
import {MembersModal} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {IUser} from '@wix/yoshi-flow-editor'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {setActiveElement} from '../../../../commons/actions/focus-handler'
import {getLanguage} from '../../../../commons/selectors/environment'
import {isPaidPlansInstalled} from '../../../../commons/selectors/installed-apps'
import {openModal} from '../../../../commons/services/modal'
import {isMember} from '../selectors/current-member-details'
import {getEvent, isRestrictedTo} from '../selectors/event'
import {inDetails, inPickTickets, inTicketForm} from '../selectors/navigation'
import {getBestNonExpiredPlan} from '../selectors/paid-plans'
import {isOrderCompleted} from '../selectors/placed-order'
import {getReservationId} from '../selectors/reservation'
import {createAsyncAction} from '../services/redux-toolkit'
import {GetState} from '../types/state'
import {setCurrentUser} from './current-user'
import {getMembers} from './event'
import {closeNativeModal} from './modals'
import {applyPlan, getPlanList} from './paid-plans'
import {getMemberRsvp} from './rsvp'

export const PROMPT_LOGIN = createActions('PROMPT_LOGIN')
export const FETCH_CURRENT_MEMBER = createActions('FETCH_CURRENT_MEMBER')

export const ensureLoggedIn = () => (dispatch: Function, getState: GetState) => {
  if (isMember(getState())) {
    return true
  }

  dispatch(closeNativeModal())
  return dispatch(promptLogin())
}

export const promptLogin =
  () =>
  async (dispatch: any, getState: GetState): Promise<boolean> => {
    const loggedIn = await dispatch(callAPI(PROMPT_LOGIN, getLanguage(getState())))
    return loggedIn
  }

export const ensureLoginForMembersOnly = () => async (dispatch: Function, getState: GetState) => {
  const state = getState()

  if (isRestrictedTo(state, VisitorType.MEMBER)) {
    return dispatch(ensureLoggedIn())
  } else {
    return true
  }
}

export const openMembersModal = (eventId: string) => async (dispatch: Function) => {
  const loggedIn = await dispatch(ensureLoggedIn())
  if (loggedIn) {
    await dispatch(openModal({type: MembersModal.LIST, params: {eventId}}))
    dispatch(setActiveElement(hookToAttributeSelector(DH.EVENT_DETAILS_MEMBERS)))
  }
}

export const fetchCurrentMember = () => (dispatch: Function) => {
  return dispatch(callAPI(FETCH_CURRENT_MEMBER))
}

export const addLoginListener = createAsyncAction(
  'ADD_LOGIN_LISTENER',
  async (_, {dispatch, getState, extra: {wixCodeApi}}) => {
    const handleUser = async (user: IUser) => {
      if (user.loggedIn) {
        dispatch(setCurrentUser({id: user.id, role: user.role, loggedIn: user.loggedIn}))

        await dispatch(fetchCurrentMember())
        await dispatch(getMemberRsvp())

        dispatch(getMembers())

        const state = getState()
        const inTicketFormAndOrderNotCompleted = inTicketForm(state) && !isOrderCompleted(state.placedOrder.order)

        if (
          (inDetails(state) || inPickTickets(state) || inTicketFormAndOrderNotCompleted) &&
          isPaidPlansInstalled(state)
        ) {
          await dispatch(getPlanList())

          if (inTicketFormAndOrderNotCompleted) {
            const newState = getState()
            const planOrderId = getBestNonExpiredPlan(newState.paidPlans.planList)?.planOrderId
            const reservationId = getReservationId(newState)
            const eventId = getEvent(state).id

            if (planOrderId && reservationId && eventId) {
              dispatch(
                applyPlan({
                  eventId,
                  reservationId,
                  planOrderId,
                }),
              )
            }
          }
        }
      }
    }
    wixCodeApi.user.onLogin(handleUser)
  },
)
