import {Element, SeatingPlan, Section} from '@wix/ambassador-seating-v1-seating-plan/types'
import {State} from '../../types/state'
import {mapMultiRowElementToRows} from '../../utils/api-data-mapper'

export const getSeatingPlan = (state: State) => state.seating.plan

export const getMappedPlanElements = (plan: SeatingPlan): Element[] =>
  plan.sections.flatMap(section => section.elements.flatMap(mapMultiRowElementToRows))

// map MultiRow element as Row elements
export const getMappedPlanSections = (plan: SeatingPlan): Section[] =>
  plan.sections.map(section => ({
    ...section,
    elements: section.elements.flatMap(mapMultiRowElementToRows),
  }))
