import {AnyAction} from 'redux'
import {DETAILS_ROUTE} from '../../../../commons/constants/navigation'
import {CHANGE_EVENT} from '../actions/event'
import {NAVIGATE, NAVIGATE_BACK} from '../actions/navigation'
import {Navigation} from '../types/state'

const defaultState: Navigation = {
  sectionPath: '',
  slug: '',
  route: DETAILS_ROUTE.DETAILS,
  query: {},
  shouldNavigateBack: false,
}

export const navigation = (state = defaultState, action: AnyAction): Navigation => {
  switch (action.type) {
    case CHANGE_EVENT:
      return {
        ...state,
        slug: action.payload.event.slug,
      }
    case NAVIGATE.SUCCESS:
      return {...state, ...action.payload}
    case NAVIGATE_BACK:
      return {...state, shouldNavigateBack: true}
    default:
      return state
  }
}
