import {Element, Place, Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {RsvpStatus} from '@wix/events-types'
import {BI_RSVP_STATUS} from '../constants/rsvp'
import {getElementIdByPlaceId} from '../selectors/seating/place'
import {AdditionalGuests, ExtractedRegFormData, RegFormData} from '../types/registration'

export const extractFormData = (data: RegFormData) => {
  const nonFormKeys = ['reservation', 'response']
  const formData = {} as ExtractedRegFormData

  Object.entries(data).forEach(([key, value]) => {
    if (!nonFormKeys.includes(key)) {
      if (key.includes('address') && value) {
        if (typeof value === 'string') {
          formData[key] = [value]
        } else {
          formData[key] = []

          // Does not work with map or for-in/of due to empty value (empty != undefined)
          for (let i = 0; i < (value as string[]).length; i++) {
            formData[key][i] = value[i] || ''
          }
        }
      } else if (key === 'additionalGuests') {
        if (instanceOfAdditionalGuests(value)) {
          formData[key] = value.additionalGuests.toString()
        } else {
          formData[key] = value.toString()
        }
      } else {
        formData[key] = value as string | string[]
      }
    }
  })

  return formData
}

const RSVP_STATUS_MAP = {
  [BI_RSVP_STATUS.YES]: RsvpStatus.YES,
  [BI_RSVP_STATUS.NO]: RsvpStatus.NO,
  [BI_RSVP_STATUS.WAITING]: RsvpStatus.WAITING,
}

export const mapRsvpStatusToBi = (newStatus: RsvpStatus) =>
  Object.entries(RSVP_STATUS_MAP).find(([, newValue]) => newValue === newStatus)[0]

const instanceOfAdditionalGuests = (object: any): object is AdditionalGuests => {
  return 'additionalGuests' in object
}

export const mapMultiRowElementToRows = (element: Element): Element[] => {
  if (element.multiRowProperties) {
    const rowPlaceMap = element.places.reduce<{[rowId: number]: Place[]}>((acc, place) => {
      const rowId = getElementIdByPlaceId(place.id)

      if (acc[rowId]) {
        acc[rowId].push(place)
      } else {
        acc[rowId] = [place]
      }

      return acc
    }, {})

    return element.multiRowProperties.rows.map(row => ({
      ...row,
      type: Type.ROW,
      elementGroupId: element.elementGroupId,
      places: rowPlaceMap[row.id].map(place => ({...place, elementType: Type.ROW})),
    }))
  } else {
    return [element]
  }
}
