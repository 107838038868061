import {AnyAction} from 'redux'
import {CHANGE_EVENT} from '../actions/event'
import {ScheduleState} from '../types/state'

const defaultState = {
  facets: {},
  items: [],
  limit: 0,
  offset: 0,
  total: 0,
} as ScheduleState

export const schedule = (state: ScheduleState = defaultState, action: AnyAction) => {
  switch (action.type) {
    case CHANGE_EVENT:
      return action.payload.schedule
    default:
      return state
  }
}
