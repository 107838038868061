import {getLanguageFromLocale as getLocaleWithDefault} from '@wix/wix-events-commons-statics'
import {CommonState} from '../types/state'

export const getLocale = (state: CommonState) => getLocaleWithDefault(state.environment.locale)

export const getLanguage = (state: CommonState) => state.environment.language

export const isPrimaryLanguage = (state: CommonState) => state.multilingual.isPrimaryLanguage

export const isEditor = (state: CommonState) => state.environment.editor

export const isSite = (state: CommonState) => state.environment.site

export const isPreview = (state: CommonState) => state.environment.preview

export const isHeadless = (state: CommonState) => state.environment.headless

export const hasHeadlessTYP = (state: CommonState) => isHeadless(state) && state.environment.hasHeadlessTYP

export const getHomePageUrl = (state: CommonState) => state.environment.homePageUrl

export const getEventListPageUrl = (state: CommonState) => state.environment.eventListPageUrl

export const getEventListPageRelativeUrl = (state: CommonState) => state.environment.eventListPageRelativeUrl

export const hasHeadlessOriginUrl = (state: CommonState) => isHeadless(state) && getHomePageUrl(state)
