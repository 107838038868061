import {useFedopsLogger, usePanorama} from '@wix/yoshi-flow-editor'

export const createLogger = ({fedops, panoramaClient}: CreateLoggerParams) => {
  return {
    startInteraction: (interaction: Interaction) => {
      fedops?.interactionStarted?.(interaction)
      panoramaClient?.transaction?.(interaction).start()
    },
    finishInteraction: (interaction: Interaction) => {
      fedops?.interactionEnded?.(interaction)
      panoramaClient?.transaction?.(interaction).finish()
    },
  }
}

interface CreateLoggerParams {
  fedops: ReturnType<typeof useFedopsLogger>
  panoramaClient: ReturnType<typeof usePanorama>
}

export enum Interaction {
  CreateReservation = 'create-reservation',
  Checkout = 'checkout',
  LoadingTickets = 'loading-tickets',
  PaymentWidgetLoad = 'PAYMENT_WIDGET_LOAD',
}

export type Logger = ReturnType<typeof createLogger>
