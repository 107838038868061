import {getEventId} from '@wix/wix-events-commons-statics'
import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {openDialogModal} from '../../../../commons/services/modal'
import {getEvent, isDemoEvent} from '../selectors/event'
import {GetState} from '../types/state'

export const GET_POLICIES = createActions('GET_POLICIES')
export const TOGGLE_AGREE_POLICIES = 'TOGGLE_AGREE_POLICIES'

export const toggleAgreePolicies = () => ({
  type: TOGGLE_AGREE_POLICIES,
})

export const getPolicies =
  ({showAlert}: {showAlert: boolean}) =>
  (dispatch: Function, getState: GetState) => {
    const state = getState()
    if (!isDemoEvent(state)) {
      const event = getEvent(state)
      return dispatch(callAPI(GET_POLICIES, getEventId(event), {showAlert}))
    }
  }

interface OpenPolicyModalArgs {
  policyId: string
  returnFocusElement: string
}
export const openPolicyModal =
  ({policyId, returnFocusElement}: OpenPolicyModalArgs) =>
  (dispatch: Function, getState: GetState) => {
    const state = getState()
    const event = getEvent(state)
    dispatch(
      openDialogModal({
        type: 'policy',
        params: {
          policyId,
          eventId: getEventId(event),
        },
        returnFocusElement,
      }),
    )
  }
