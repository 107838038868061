import {AnyAction} from 'redux'
import {DELETE_RSVP, GET_MEMBER_RSVP, SEND_RSVP, SKIP_GET_MEMBER_EVENT_RSVP, UPDATE_RSVP_STATUS} from '../actions/rsvp'
import {MemberRsvp} from '../types/state'

const getDefaultState = (): MemberRsvp => ({
  rsvp: null,
  loaded: false,
})

export const memberRsvp = (state = getDefaultState(), action: AnyAction) => {
  switch (action.type) {
    case GET_MEMBER_RSVP.SUCCESS:
      return {...state, rsvp: action.payload, loaded: true}
    case SEND_RSVP.SUCCESS:
      return {...state, rsvp: action.payload.rsvp, loaded: true}
    case DELETE_RSVP.REQUEST:
      return {...state, rsvp: null, loaded: true}
    case UPDATE_RSVP_STATUS.SUCCESS:
      const [, , status] = action.args
      return {...state, rsvp: {...state.rsvp, status}}
    case GET_MEMBER_RSVP.REQUEST:
      return {...state, loaded: false}
    case GET_MEMBER_RSVP.FAILURE:
      return {...state, loaded: true}
    case SKIP_GET_MEMBER_EVENT_RSVP:
      return {...state, loaded: true}
    default:
      return state
  }
}
