import {ReservationStatus} from '@wix/events-types'
import {DETAILS_ROUTE} from '../../../commons/constants/navigation'
import {defaultCheckoutState} from '../Widget/reducers/checkout'
import {getDefaultInvoiceState} from '../Widget/reducers/invoice'
import {Navigation, SelectedTicket, State} from '../Widget/types/state'
import {Api} from '../Widget/utils/api'

export const getStateFromInvoice = async ({
  serverApi,
  event,
  navigation,
}: {
  serverApi: Api
  event: wix.events.Event
  navigation: Navigation
}): Promise<Partial<State>> => {
  if (navigation.route === DETAILS_ROUTE.TICKET_FORM && event?.id) {
    const {
      query: {reservationId},
    } = navigation

    if (reservationId && reservationId !== 'undefined') {
      try {
        const invoice = await serverApi.getInvoice(event.id, reservationId)

        if (invoice.reservationStatus === ReservationStatus.RESERVATION_PENDING) {
          const selectedTickets = invoice.reservations.reduce((acc, item) => {
            const priceOptionIds = item.ticketDetails
              .reduce((accumulator, ticketDetails) => {
                for (let i = 0; i < ticketDetails.capacity; i++) {
                  accumulator.push(ticketDetails.pricingOptionId)
                }
                return accumulator
              }, [])
              .filter(a => a)

            return {
              ...acc,
              [item.ticket.id]: {
                quantity: item.quantity,
                donation: item.ticketDetails[0]?.priceOverride,
                pricingOptionIds: priceOptionIds.length ? priceOptionIds : null,
              } as SelectedTicket,
            }
          }, {} as Record<string, SelectedTicket>)

          const ticketsDetailsValidity = invoice.reservations.reduce(
            (acc, ticketReservation) => [...acc, ...Array(ticketReservation.quantity).fill(false)],
            [],
          )
          return {
            invoice: {
              ...getDefaultInvoiceState(),
              invoice: invoice.invoice,
            },
            selectedTickets,
            checkout: {
              ...defaultCheckoutState,
              ticketsDetailsValidity,
            },
            reservation: {
              data: {
                id: reservationId,
                expires: invoice.expires,
              },
              error: null,
            },
          }
        }

        return {}
      } catch (e) {
        return {}
      }
    }
  }

  return {}
}
