import {VisitorType} from '@wix/events-types'
import {
  BI_ENDPOINTS,
  BI_ENTITY_TYPE,
  BI_ORIGINS,
  MembersModal,
  getCouponDiscountId,
  getCurrency,
  getEventId,
  isInitiallyTicketed,
  isTicketed,
} from '@wix/wix-events-commons-statics'
import {AnyAction} from 'redux'
import {EventMap} from '../../../../commons/bi/interfaces'
import {MODAL_OPENED} from '../../../../commons/services/modal'
import {ADD_TO_CALENDAR_CLICKED, addToCalendarOptionClicked} from '../actions/calendar'
import {COUPON_APPLIED} from '../actions/coupon'
import {SHARE_EVENT} from '../actions/event'
import {JOIN_GROUP} from '../actions/groups'
import {
  DETAILS_PAGE_LOADED,
  FORM_PAGE_LOADED,
  THANK_YOU_PAGE_LOADED,
  TICKETED_THANK_YOU_PAGE_LOADED,
} from '../actions/loaded'
import {_openNativeModal} from '../actions/modals'
import {DOWNLOAD_TICKETS} from '../actions/order-success'
import {PLACE_ORDER, pollOrder} from '../actions/placed-order'
import {REGISTRATION_BUTTON_CLICKED} from '../actions/registration'
import {RESERVE_TICKETS} from '../actions/reservation'
import {SEND_RSVP} from '../actions/rsvp'
import {seatingMapButtonClick} from '../actions/seating/bi'
import {NativeModal} from '../components/modals/constants'
import {BI_RSVP_STATUS} from '../constants/rsvp'
import {resetFilters, selectLocation, selectPrice} from '../reducers/seating/filters'
import {setShowAccessibilityMode} from '../reducers/seating/mode'
import {updatePlace} from '../reducers/seating/places'
import {getEvent, isRestrictedTo} from '../selectors/event'
import {getGroupId} from '../selectors/groups'
import {getInvoice} from '../selectors/invoice'
import {getReservationState} from '../selectors/navigation'
import {
  getGrandTotal,
  getNonFreeTicketsCount,
  getOrderNumber,
  getPaymentMethod,
  getTicketQuantity,
} from '../selectors/placed-order'
import {DEFAULT_OPTION_ID} from '../selectors/seating/filters'
import {isPlaceReservableAsWhole} from '../selectors/seating/place'
import {getPlace} from '../selectors/seating/places'
import {
  getNonFreeSelectedTicketsCount,
  getSelectedTicketsQuantity,
  getTotalOrderRevenue,
} from '../selectors/selected-tickets'
import {getTickets} from '../selectors/tickets'
import {State} from '../types/state'
import {mapRsvpStatusToBi} from '../utils/api-data-mapper'

export const eventMap: EventMap = {
  [addToCalendarOptionClicked.type]: (
    state: State,
    {payload: {origin, sourcePage}}: ReturnType<typeof addToCalendarOptionClicked>,
  ) => {
    const event = getEvent(state)

    return {
      evid: 5,
      event_id: getEventId(event),
      origin,
      source_page: sourcePage,
    }
  },
  [pollOrder.fulfilled.toString()]: (state: State) => {
    const event = getEvent(state)
    const orderNumber = getOrderNumber(state)

    return {
      evid: 6,
      entity_type: BI_ENTITY_TYPE.order,
      entity_id: orderNumber,
      event_id: getEventId(event),
      origin: 'ORDER_STATUS_PAGE',
      error_message: 'Poll_Order_Success',
    }
  },
  [pollOrder.rejected.toString()]: (state: State) => {
    const event = getEvent(state)
    const orderNumber = getOrderNumber(state)

    return {
      evid: 6,
      entity_type: BI_ENTITY_TYPE.order,
      entity_id: orderNumber,
      event_id: getEventId(event),
      origin: 'ORDER_STATUS_PAGE',
      error_message: 'Poll_Order_Failed',
    }
  },
  [DETAILS_PAGE_LOADED]: (state: State) => {
    const event = getEvent(state)
    const ticketed = isTicketed(event)

    return {
      evid: 507,
      ticket_definition_count: ticketed ? state.tickets.length : (null as any),
      currency: ticketed ? getCurrency(event) : (null as any),
      event_id: getEventId(event),
      type_of_event: isInitiallyTicketed(event) ? 'ticketed' : 'rsvp',
    }
  },
  [RESERVE_TICKETS.REQUEST]: (state: State) => {
    const event = getEvent(state)

    return {
      evid: 508,
      ticket_count: getSelectedTicketsQuantity(state),
      ticket_definition_count: getTickets(state).length,
      currency: getCurrency(event),
      event_id: getEventId(event),
      total_order_revenue: toBiMoney(getTotalOrderRevenue(state)),
      nonfree_ticket_count: getNonFreeSelectedTicketsCount(state),
      is_member_only_event: isRestrictedTo(state, VisitorType.MEMBER),
      type_of_event: 'ticketed',
    }
  },
  [REGISTRATION_BUTTON_CLICKED]: (state: State) => {
    const event = getEvent(state)
    const ticketed = isTicketed(event)

    return {
      evid: 508,
      ticket_count: ticketed ? getSelectedTicketsQuantity(state) : (null as any),
      ticket_definition_count: ticketed ? getTickets(state).length : (null as any),
      currency: ticketed ? getCurrency(event) : (null as any),
      event_id: getEventId(event),
      total_order_revenue: ticketed ? toBiMoney(getTotalOrderRevenue(state)) : (null as any),
      nonfree_ticket_count: ticketed ? getNonFreeSelectedTicketsCount(state) : (null as any),
      is_member_only_event: isRestrictedTo(state, VisitorType.MEMBER),
      type_of_event: 'rsvp',
    }
  },
  [FORM_PAGE_LOADED]: (state: State) => {
    const event = getEvent(state)
    const ticketed = isTicketed(event)

    return {
      evid: 509,
      origin: null as any,
      event_id: getEventId(event),
      currency: ticketed ? getCurrency(event) : (null as any),
      ticket_count: ticketed ? getSelectedTicketsQuantity(state) : (null as any),
      ticket_definition_count: ticketed ? state.tickets.length : (null as any),
      total_order_revenue: ticketed ? toBiMoney(getTotalOrderRevenue(state)) : (null as any),
      nonfree_ticket_count: ticketed ? getNonFreeSelectedTicketsCount(state) : (null as any),
    }
  },
  [PLACE_ORDER.REQUEST]: (state: State) => {
    const event = getEvent(state)
    const totalOrderRevenue = getTotalOrderRevenue(state) * 100

    return {
      evid: 510,
      button_name: totalOrderRevenue === 0 ? 'submit' : 'choose_payment',
      ticket_count: getSelectedTicketsQuantity(state),
      currency: getCurrency(event),
      ticket_definition_count: state.tickets.length,
      event_id: getEventId(event),
      total_order_revenue: totalOrderRevenue,
      nonfree_ticket_count: getNonFreeSelectedTicketsCount(state),
      visitor_rsvp_count: null as any,
      rsvp_type: null as any,
    }
  },
  [DOWNLOAD_TICKETS]: (state: State, {payload}: AnyAction) => ({
    evid: 88,
    event_id: getEventId(getEvent(state)),
    origin: BI_ORIGINS.THANK_YOU_PAGE,
    button_name: payload.buttonName,
  }),
  [_openNativeModal.toString()]: (state: State, action: AnyAction) => {
    if (_openNativeModal.match(action)) {
      if (action.payload.type === NativeModal.TICKETS_DOWNLOAD) {
        return {
          evid: 239,
          event_id: getEventId(getEvent(state)),
        }
      }

      return null
    }
  },
  [MODAL_OPENED]: (state: State, action: AnyAction) => {
    if (action.modalType === MembersModal.LIST) {
      return {
        evid: 89,
        event_id: getEventId(getEvent(state)),
      }
    }
    return null
  },
  [SEND_RSVP.REQUEST]: (state: State, action: AnyAction) => {
    const [, formData, status] = action.args
    const totalGuests = 1 + (Number(formData.additionalGuests) || 0)
    const oldStatus = mapRsvpStatusToBi(status)

    return {
      evid: 510,
      button_name: 'submit',
      ticket_count: null as any,
      currency: null as any,
      ticket_definition_count: null as any,
      event_id: getEventId(getEvent(state)),
      total_order_revenue: null as any,
      nonfree_ticket_count: null as any,
      visitor_rsvp_count: totalGuests,
      rsvp_type: oldStatus,
    }
  },
  [TICKETED_THANK_YOU_PAGE_LOADED]: (state: State) => {
    const event = getEvent(state)
    return {
      evid: 511,
      currency: getCurrency(event),
      ticket_count: getTicketQuantity(state),
      ticket_definition_count: null as any,
      page_type: getReservationState(state),
      event_id: getEventId(event),
      total_order_revenue: toBiMoney(getGrandTotal(state)),
      nonfree_ticket_count: getNonFreeTicketsCount(state),
      paymentmethod: getPaymentMethod(state),
    }
  },
  [THANK_YOU_PAGE_LOADED]: (state: State) => ({
    evid: 512,
    event_id: getEventId(getEvent(state)),
    visitor_rsvp_count: state.guest?.totalGuests,
    rsvp_type: state.guest?.status ? mapRsvpStatusToBi(Number(state.guest.status)) : BI_RSVP_STATUS.YES,
  }),
  [SHARE_EVENT]: (state: State, action: AnyAction) => ({
    evid: 513,
    event_id: getEventId(getEvent(state)),
    origin: action.payload.origin,
    button_name: action.payload.buttonName,
  }),
  [COUPON_APPLIED]: (state: State) => ({
    evid: 515,
    event_id: getEventId(getEvent(state)),
    couponId: getCouponDiscountId(getInvoice(state)),
    ticket_types: [],
  }),
  [ADD_TO_CALENDAR_CLICKED]: (state: State) => ({
    evid: 182,
    event_id: getEventId(getEvent(state)),
    orderId: isTicketed(getEvent(state)) ? getOrderNumber(state) : null,
  }),
  [JOIN_GROUP]: (state: State) => ({
    evid: 229,
    event_id: getEventId(getEvent(state)),
    group_id: getGroupId(state),
    orderId: isTicketed(getEvent(state)) ? getOrderNumber(state) : null,
    origin: 'thankyou_page',
  }),
  [selectPrice.toString()]: (state: State, action: AnyAction) => {
    if (selectPrice.match(action)) {
      return {
        evid: 253,
        event_id: getEvent(state).id,
        filter_name: 'price',
        filterValue: action.payload === null ? DEFAULT_OPTION_ID : action.payload,
        seating_map_guid: state.seating.plan.id,
      }
    }
  },
  [selectLocation.toString()]: (state: State, action: AnyAction) => {
    if (selectLocation.match(action)) {
      return {
        evid: 253,
        event_id: getEvent(state).id,
        filter_name: 'zone',
        filterValue: action.payload === null ? DEFAULT_OPTION_ID : action.payload,
        seating_map_guid: state.seating.plan.id,
      }
    }
  },
  [resetFilters.toString()]: (state: State) => ({
    evid: 253,
    event_id: getEvent(state).id,
    filter_name: 'refresh_filters',
    seating_map_guid: state.seating.plan.id,
  }),
  [updatePlace.toString()]: (state: State, action: AnyAction) => {
    if (updatePlace.match(action)) {
      const {
        place: {id: placeId, quantity: newQuantity},
        origin,
      } = action.payload
      const place = getPlace(state, placeId)
      const reserveWholeElement = isPlaceReservableAsWhole(place)
      const seat_guid = reserveWholeElement ? null : placeId
      const eventId = getEvent(state).id

      if (newQuantity !== undefined && place.quantity !== newQuantity) {
        return {
          evid: 251,
          action: newQuantity && place.quantity < newQuantity ? 'select' : 'delete',
          element_guid: place.id,
          element_type: place.elementType,
          event_guid: eventId,
          origin,
          seat_guid,
          seating_map_guid: state.seating.plan.id,
          is_whole_table: reserveWholeElement,
        }
      }
      return null
    }
  },
  [setShowAccessibilityMode.toString()]: (state: State, action: AnyAction) => {
    if (setShowAccessibilityMode.match(action)) {
      return {
        evid: 252,
        event_guid: getEvent(state).id,
        seating_map_guid: state.seating.plan.id,
        tab_name: action.payload ? 'ticket_list' : 'basket',
      }
    }
  },
  [seatingMapButtonClick.toString()]: (state: State, action: AnyAction) => {
    if (seatingMapButtonClick.match(action)) {
      return {
        evid: 254,
        button_name: action.payload,
        event_id: getEvent(state).id,
        seating_map_guid: state.seating.plan.id,
      }
    }
  },
  endpoint: BI_ENDPOINTS.EVENTS_UOU,
}

const toBiMoney = (amount: any) => Math.round(amount * 100)
