import {NativeModal} from '../components/modals/constants'
import {ModalsPayload, State} from '../types/state'

export const getModalType = (state: State) => {
  return state.modals.modalType || ''
}

export const getModalPayload = <T extends NativeModal>(state: State): ModalsPayload<T> => {
  return state.modals.payload as ModalsPayload<T>
}

export const isSeatingModalOpen = (state: State) => getModalType(state) === NativeModal.SEATING_PLAN
