import {createReducer} from '@reduxjs/toolkit'
import type {ConsentPolicy} from '@wix/platform-editor-sdk'
import {setConsentPolicy} from '../actions/consent-policy'

const defaultState: ConsentPolicy = {
  defaultPolicy: true,
  policy: {
    functional: true,
    analytics: true,
    advertising: true,
    dataToThirdParty: true,
    essential: true,
  },
}

export const consentPolicy = createReducer(defaultState, builder => {
  builder.addCase(setConsentPolicy, (_state, action) => action.payload)
})
