import {createAction} from '@reduxjs/toolkit'
import {getEventId} from '@wix/wix-events-commons-statics'
import {getEvent} from '../selectors/event'
import {getReservationId} from '../selectors/reservation'
import {GetState} from '../types/state'
import {getDiscount} from './checkout'
import {updateOrder} from './placed-order'

export const giftCardApplied = createAction('GIFT_CARD_APPLIED')

export const resetGiftCardCode = createAction('RESET_GIFT_CARD_CODE')

export const submitGiftCard = createAction<string>('SUBMIT_GIFT_CARD')

export const applyGiftCard = (eventId: string, reservationId: string, giftCardCode: string) => (dispatch: Function) => {
  dispatch(submitGiftCard(giftCardCode))

  return dispatch(getDiscount({eventId, reservationId}))
}

export const removeGiftCard = () => (dispatch: Function, getState: GetState) => {
  const state = getState()

  dispatch(resetGiftCardCode())
  state.placedOrder.order
    ? dispatch(updateOrder({buyer: state.checkout.buyerDetails, guests: state.checkout.ticketsDetails}))
    : dispatch(getDiscount({eventId: getEventId(getEvent(state)), reservationId: getReservationId(state)}))
}
