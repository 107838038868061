import {AnyAction} from 'redux'
import {
  CLEAR_CHECKOUT,
  EDIT_STEP,
  NEXT_FORM_CLICKED,
  NEXT_STEP,
  SET_EXPANDED_TICKET_INDEX,
  SET_TICKET_DETAILS,
  SET_VALID_PAYMENT_ADDED,
  setBuyerDetails,
  setUseBuyerDetails,
} from '../actions/checkout'
import {RESERVE_TICKETS} from '../actions/reservation'
import {CHECKOUT_STEPS} from '../constants/constants'
import {CheckoutState} from '../types/state'

export const defaultCheckoutState: CheckoutState = {
  useBuyerDetails: false,
  currentStep: CHECKOUT_STEPS[0],
  validPaymentAdded: false,
  buyerDetails: null,
  ticketsDetails: [],
  ticketsDetailsValidity: [],
  expandedTicketIndex: 0,
}

export const checkout = (state = defaultCheckoutState, action: AnyAction): CheckoutState => {
  switch (action.type) {
    case setBuyerDetails.toString():
      if (setBuyerDetails.match(action)) {
        const buyerDetails = action.payload

        if (state.useBuyerDetails) {
          const ticketsDetails = [...state.ticketsDetails]

          ticketsDetails[0] = {
            ...ticketsDetails[0],
            ...buyerDetails,
          }
          return {...state, buyerDetails, ticketsDetails}
        } else {
          return {
            ...state,
            buyerDetails,
          }
        }
      }

      return state
    case SET_TICKET_DETAILS:
      return setTicketDetails(state, action)
    case SET_VALID_PAYMENT_ADDED:
      return {
        ...state,
        validPaymentAdded: action.payload.validPaymentAdded,
      }
    case setUseBuyerDetails.toString():
      return handleUseBuyerDetails(state, action)
    case NEXT_STEP:
      return {
        ...state,
        currentStep: action.payload.nextStep,
      }
    case EDIT_STEP:
      const {currentStep, validPaymentAdded} = action.payload
      return {
        ...state,
        currentStep,
        validPaymentAdded,
      }
    case NEXT_FORM_CLICKED:
      return {
        ...state,
        expandedTicketIndex: state.expandedTicketIndex + 1,
      }
    case SET_EXPANDED_TICKET_INDEX:
      return {
        ...state,
        expandedTicketIndex: action.payload.index,
      }
    case RESERVE_TICKETS.SUCCESS: {
      const ticketsDetailsValidity = action.payload.reservations.reduce(
        (acc, ticketReservation) => [...acc, ...Array(ticketReservation.quantity).fill(false)],
        [],
      )
      return {...state, ticketsDetailsValidity}
    }
    case CLEAR_CHECKOUT:
      return defaultCheckoutState
    default:
      return state
  }
}

const handleUseBuyerDetails = (state: CheckoutState, action: AnyAction): CheckoutState => {
  if (setUseBuyerDetails.match(action)) {
    const {
      buyerDetails: {firstName, lastName, email},
    } = state
    const {useBuyerDetails, ticketIndex = 0} = action.payload

    const shouldUseBuyerDetails = useBuyerDetails
    const buyerDetails = shouldUseBuyerDetails
      ? {firstName, lastName, email}
      : {firstName: null, lastName: null, email: null}
    const ticketsDetails = [...state.ticketsDetails]

    ticketsDetails[ticketIndex] = {
      ...ticketsDetails[ticketIndex],
      ...buyerDetails,
    }

    return {
      ...state,
      ticketsDetails,
      useBuyerDetails: shouldUseBuyerDetails,
    }
  }
}

const setTicketDetails = (state: CheckoutState, action: AnyAction) => {
  const ticketsDetails = [...state.ticketsDetails]
  const ticketsDetailsValidity = [...state.ticketsDetailsValidity]

  ticketsDetails[action.payload.index] = action.payload.details
  ticketsDetailsValidity[action.payload.index] = action.payload.valid

  return {
    ...state,
    ticketsDetails,
    ticketsDetailsValidity,
  }
}
