import {RsvpStatus} from '@wix/events-types'
import {AnyAction} from 'redux'
import {CHANGE_RESPONSE, EDIT_RSVP_STEP, NEXT_RSVP_STEP, SET_RSVP_DETAILS} from '../actions/rsvp'
import {RSVP_STEPS} from '../constants/constants'
import {RsvpState} from '../types/state'

const defaultState: RsvpState = {
  currentStep: RSVP_STEPS[0],
  rsvpDetails: null,
  response: RsvpStatus.YES,
}

export const rsvp = (state = defaultState, action: AnyAction): RsvpState => {
  switch (action.type) {
    case SET_RSVP_DETAILS:
      return {
        ...state,
        rsvpDetails: action.payload.rsvpDetails,
      }
    case NEXT_RSVP_STEP:
      return {
        ...state,
        currentStep: action.payload.nextStep,
      }
    case EDIT_RSVP_STEP:
      const {currentStep} = action.payload
      return {
        ...state,
        currentStep,
      }
    case CHANGE_RESPONSE:
      return {
        ...state,
        response: action.payload.response,
      }
    default:
      return state
  }
}
