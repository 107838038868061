import jwtDecode from 'jwt-decode'

interface TokenValues {
  exp: number
  data: string
}

export const validateToken = (token: string) => {
  if (!token) {
    return {tokenValid: false, errorMessage: 'Token is missing'}
  }

  try {
    const decodedToken = jwtDecode<TokenValues>(token.replace('JWS.', ''))

    if (isTokenExpired(decodedToken.exp)) {
      return {tokenValid: false, errorMessage: 'Token expired'}
    }

    const {eventId, instanceId, orderNumber, reservationId} = JSON.parse(decodedToken.data)

    if (!eventId || !instanceId || !orderNumber || !reservationId) {
      return {tokenValid: false, errorMessage: 'Token is missing required fields'}
    }

    return {tokenValid: true}
  } catch (error) {
    return {tokenValid: false, errorMessage: 'Failed to decode token'}
  }
}

const isTokenExpired = (exp: number) => {
  return exp < Math.floor(Date.now() / 1000)
}
