import {
  EVENTS,
  EVENT_CATEGORY,
  LIST,
  ORIGIN,
  TICKET_TYPE,
  getCouponDiscountCode,
  getEventTitle,
  getPricingOption,
  isTicketed,
} from '@wix/wix-events-commons-statics'
import {ITrackEventName, IWixAPI, usePanorama} from '@wix/yoshi-flow-editor'
import {AnyAction} from 'redux'
import {trackEvent} from '../../../../commons/utils/wix-code-api'
import {NEXT_FORM_CLICKED} from '../actions/checkout'
import {COUPON_APPLIED} from '../actions/coupon'
import {DETAILS_PAGE_LOADED} from '../actions/loaded'
import {PAYMENT_METHOD_SELECTED, PLACE_ORDER_BUTTON_CLICKED} from '../actions/payment'
import {REGISTRATION_BUTTON_CLICKED} from '../actions/registration'
import {RESERVE_TICKETS} from '../actions/reservation'
import {SEND_RSVP} from '../actions/rsvp'
import {selectTicket} from '../actions/selected-tickets'
import {getEvent} from '../selectors/event'
import {getSelectedTicketQuantity} from '../selectors/selected-tickets'
import {getTicketById, getTickets} from '../selectors/tickets'
import {State} from '../types/state'

export const userEventsLogger =
  ({wixCodeApi, logger}: {wixCodeApi: IWixAPI; logger: ReturnType<typeof usePanorama>['logger']}) =>
  ({getState}) =>
  (next: Function) =>
  (action: AnyAction) => {
    switch (action.type) {
      case DETAILS_PAGE_LOADED:
        detailsPageLoaded(wixCodeApi, getState())
        break
      case selectTicket.fulfilled.toString():
        changeTicketCount(wixCodeApi, getState(), action)
        break
      case RESERVE_TICKETS.SUCCESS:
        reserveTicketsSuccess(wixCodeApi, getState())
        break
      case PAYMENT_METHOD_SELECTED:
        paymentMethodSelected(wixCodeApi, action)
        break
      case PLACE_ORDER_BUTTON_CLICKED:
        placeOrderButtonClicked(wixCodeApi, getState(), logger)
        break
      case NEXT_FORM_CLICKED:
        nextFormClicked(wixCodeApi)
        break
      case COUPON_APPLIED:
        couponApplied(wixCodeApi)
        break
      case REGISTRATION_BUTTON_CLICKED:
        registrationButtonClicked(wixCodeApi, getState())
        break
      case SEND_RSVP.REQUEST:
        sendRsvpRequest(wixCodeApi, getState())
        break
      default:
        break
    }

    return next(action)
  }

const detailsPageLoaded = (wixCodeApi: IWixAPI, state: State) => {
  const event = getEvent(state)

  if (isTicketed(event)) {
    trackEvent(wixCodeApi, EVENTS.ViewContent, {
      origin: ORIGIN,
      name: getEventTitle(event),
      list: LIST,
    } as any)
  } else {
    trackEvent(wixCodeApi, EVENTS.CustomEvent, {
      event: 'Name:rsvpContentView',
      name: getEventTitle(event),
      eventCategory: EVENT_CATEGORY,
      eventLabel: ORIGIN,
    } as any)
  }
}

const changeTicketCount = (wixCodeApi: IWixAPI, state: State, action: AnyAction) => {
  if (selectTicket.fulfilled.match(action)) {
    const {ticketId: selectedTicketId, count, pricingOptionId} = action.payload
    const previousCount = getSelectedTicketQuantity(state, selectedTicketId, pricingOptionId)
    const type: ITrackEventName = count > previousCount ? EVENTS.AddToCard : EVENTS.RemoveFromCart
    const quantity: number = Math.abs(count - previousCount)
    const idsEqual = (ticket: wix.events.ticketing.TicketDefinition) => ticket.id === selectedTicketId
    const ticket = getTicketById(getTickets(state), selectedTicketId)
    const {
      price: {amount, currency},
      free,
      name,
    } = ticket
    const price = pricingOptionId ? getPricingOption(ticket, pricingOptionId).price.amount : amount
    const position = getTickets(state).findIndex(idsEqual) + 1
    trackEvent(wixCodeApi, type, {
      origin: ORIGIN,
      name,
      price,
      currency,
      variant: free ? TICKET_TYPE.Free : TICKET_TYPE.Regular,
      position,
      quantity,
      list: getEvent(state)?.id,
    })
  }
}

const reserveTicketsSuccess = (wixCodeApi: IWixAPI, state: State) => {
  const {selectedTickets} = state
  const tickets = getTickets(state)

  const contents = Object.keys(selectedTickets).map(ticketId => {
    const idsEqual = (ticket: wix.events.ticketing.TicketDefinition) => ticket.id === ticketId
    const {
      name,
      price: {amount, currency},
      free,
    } = tickets.find(idsEqual)
    const position = tickets.findIndex(idsEqual) + 1
    return {
      name,
      price: amount,
      currency,
      variant: free ? TICKET_TYPE.Free : TICKET_TYPE.Regular,
      quantity: selectedTickets[ticketId],
      position,
    }
  })
  trackEvent(wixCodeApi, EVENTS.InitiateCheckout, {
    origin: ORIGIN,
    contents,
  })
}

const paymentMethodSelected = (wixCodeApi: IWixAPI, action: AnyAction) =>
  trackEvent(wixCodeApi, EVENTS.AddPaymentInfo, {
    origin: ORIGIN,
    option: action.payload.option,
  })

const placeOrderButtonClicked = (
  wixCodeApi: IWixAPI,
  {placedOrder}: State,
  logger: ReturnType<typeof usePanorama>['logger'],
) => {
  logger().debug('Checkout: Place Order Button Clicked')
  const {invoice, orderNumber} = placedOrder.order

  const {items, revenue, tax} = invoice

  const contents = items.map(ticket => ({
    id: ticket.id,
    name: ticket.name,
    quantity: ticket.quantity,
    price: Number(ticket.price.amount),
    currency: ticket.price.currency,
  }))

  logger().debug('Checkout: Place Order Button Clicked', {
    id: orderNumber,
    origin: ORIGIN,
    revenue: Number(revenue?.amount),
    tax: Number(tax?.amount.amount),
    currency: invoice?.total?.currency,
    coupon: getCouponDiscountCode(invoice),
    contents,
  })

  trackEvent(wixCodeApi, EVENTS.Purchase, {
    id: orderNumber,
    origin: ORIGIN,
    revenue: Number(revenue?.amount),
    tax: Number(tax?.amount.amount),
    currency: invoice?.total?.currency,
    coupon: getCouponDiscountCode(invoice),
    contents,
  })
}

const nextFormClicked = (wixCodeApi: IWixAPI) =>
  trackEvent(wixCodeApi, EVENTS.CustomEvent, {
    event: 'addAssigned',
    eventCategory: EVENT_CATEGORY,
    eventLabel: ORIGIN,
  } as any)

const couponApplied = (wixCodeApi: IWixAPI) =>
  trackEvent(wixCodeApi, EVENTS.CustomEvent, {
    event: 'addCoupon',
    eventCategory: EVENT_CATEGORY,
    eventLabel: ORIGIN,
  } as any)

const registrationButtonClicked = (wixCodeApi: IWixAPI, state: State) =>
  trackEvent(wixCodeApi, EVENTS.CustomEvent, {
    event: 'Name:rsvpRegisterNext',
    name: getEventTitle(getEvent(state)),
    eventCategory: EVENT_CATEGORY,
    eventLabel: ORIGIN,
  } as any)

const sendRsvpRequest = (wixCodeApi: IWixAPI, state: State) =>
  trackEvent(wixCodeApi, EVENTS.CustomEvent, {
    event: 'Name:rsvpSubmit',
    name: getEventTitle(getEvent(state)),
    eventCategory: EVENT_CATEGORY,
    eventLabel: ORIGIN,
  } as any)
