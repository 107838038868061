import {isTicketed} from '@wix/wix-events-commons-statics'
import {State} from '../types/state'
import {getEvent} from './event'
import {getInvoice} from './placed-order'
import {getSortedSelectedTickets} from './selected-tickets'
import {getTickets, isFixedPriceTicketSelected} from './tickets'

export const isCouponsAccepted = (state: State) => state.checkoutOptions.options.acceptCoupons

export const isGiftCardsAccepted = (state: State) => state.checkoutOptions.options.acceptGiftCards

export const isUpgradeNeededForCheckout = (state: State) => {
  const {
    grandTotal: {amount},
  } = getInvoice(state)

  return !state.checkoutOptions.options.premiumServices && Number(amount) > 0
}

export const isFixedPriceTicketsSelected = (state: State) => {
  const event = getEvent(state)
  const tickets = getTickets(state)
  const sortedSelectedTickets = getSortedSelectedTickets(state)

  return isTicketed(event) && isFixedPriceTicketSelected(tickets, sortedSelectedTickets)
}

export const isCouponInputVisible = (state: State) => isFixedPriceTicketsSelected(state) && isCouponsAccepted(state)

export const isGiftCardInputVisible = (state: State) => isFixedPriceTicketsSelected(state) && isGiftCardsAccepted(state)
