import {createAction} from '@reduxjs/toolkit'
import type {ConsentPolicy} from '@wix/platform-editor-sdk'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

export const subscribeToConsentPolicyChanges = (dispatch, controllerFlowAPI: ControllerFlowAPI) => {
  try {
    const handleConsentPolicyChange = (policy: ConsentPolicy) => {
      if (policy.policy) {
        dispatch(setConsentPolicy(policy))
      }
    }

    const currentConsentPolicy = controllerFlowAPI.viewerScriptFlowAPI.userAPI.getCurrentConsentPolicy()
    handleConsentPolicyChange(currentConsentPolicy)

    // @ts-expect-error
    controllerFlowAPI.viewerScriptFlowAPI.userAPI.onConsentPolicyChanged(handleConsentPolicyChange)
  } catch (e) {
    console.error(e)
    controllerFlowAPI.errorMonitor?.captureException?.(e)
  }
}

export const setConsentPolicy = createAction<ConsentPolicy>('SET_CONSENT_POLICY')
